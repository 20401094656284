@import "bootstrap";
@import "themes";
@import "global";

#dropdown {
  h4 {
    display: inline-block;
  }

  .badge-pill {
    margin: 5px;
  }
  .badge-pill:hover {
    opacity: 0.75;
  }

  .arrow-down {
    transition: transform 250ms, color $theme-toggle-time;
    @include themed() {
      color: t("secondary-color");
    }
    margin-top: -5px;
  }
  .arrow-down:hover {
    opacity: 0.5;
  }

  .arrow-up {
    transition: transform 250ms, color $theme-toggle-time;
    @include themed() {
      color: t("secondary-color");
    }
    margin-top: -5px;
    transform: rotate(180deg);
  }
  .arrow-up:hover {
    opacity: 0.5;
  }

  .dropdown-content-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .dropdown-content-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 250ms, transform 250ms;;
  }
  .dropdown-content-exit {
    opacity: 1;
  }
  .dropdown-content-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 250ms, transform 250ms;
  }
}