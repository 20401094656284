@import "bootstrap";
@import "themes";
@import "global";

#about {
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
}

.about-waleed-img {
  display: block;
  margin: auto;
  transition: border $theme-toggle-time;
  @include themed() {
    border: 0.5rem solid t("secondary-color");
  }

  max-height: 30rem;
  width: auto;
}


.about-secondary-color {
  transition: color $theme-toggle-time;
  @include themed() {
    color: t("secondary-color");
  }
}

// Bootstrap breakpoint to optimize when layout changes
@include media-breakpoint-down(md) {
  .about-intro {
    margin-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
  }
}

// I tried to be clever and only activate a break to put the typewriter on a newline
// when it needed more space, but I had trouble getting it to work properly on all
// screen sizes

// .about-intro {
//   br {
//     display: none;
//   }
// }

// Breakpoint to ensure TypeWriter text never overflows to newline
// @media screen and (max-width: 1446px) {
//   .about-intro {
//     br {
//       display: inline-block;
//     }
//   }
// }
