@import "bootstrap";
@import "themes";
@import "global";

#skills {
  margin-top: $spacing-between-sections;

  h1 {
    margin-left: 5%;
  
    hr {
      margin-top: 3%;
    }
  }

  h2 {
    font-size: 1.5rem;
  }
}

.skill-cards {
  margin-left: 5%;
  margin-right: 5%;
}

.skill-card {
  height: 100%;
}

@include media-breakpoint-down(md) {
  .skill-cards {
    .col-md-12 {
      margin-bottom: 30px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .skill-cards {
    .middle-card {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: $max-width-font-reduce) {
  #skills {
    h2 {
      font-size: 1rem;
    }

    .col-md-12 {
      margin-bottom: 10px;
    }

    .middle-card {
      margin-bottom: 10px;
    }
  }
}