@import "bootstrap";
@import "themes";
@import "global";

.experience-carousel {
  .arrow-col {
    display: flex;
    align-items: center;
  }
  .arrow-col:hover {
    .arrow {
      opacity: 0.5;
    }
  }

  .arrow {
    transition: color $theme-toggle-time;
    @include themed() {
      color: t("secondary-color");
    }
    margin: auto;
  }

  .fade-left-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  .fade-left-enter-active {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-left-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-left-exit-active {
    opacity: 0;
    transform: translateX(-100%);
  }

  .fade-right-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  .fade-right-enter-active {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-right-exit {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-right-exit-active {
    opacity: 0;
    transform: translateX(100%);
  }

  .fade-left-enter-active,
  .fade-left-exit-active,
  .fade-right-enter-active,
  .fade-right-exit-active {
    transition: opacity 250ms, transform 250ms;
  }

  .map-marker {
    margin-left: 10%;
  }

  .calendar,
  .map-marker {
    margin-right: 10px;
  }

  .position-title {
    font-weight: 600;
  }

  .company-name {
    transition: color $theme-toggle-time;
    @include themed() {
      color: t("secondary-color");
    }
  }

  .experience-bullets {
    margin-top: 20px;
    text-align: left;
  }

  .company-logo-col {
    display: flex;
    align-items: center;
    width: 100%;
  }

  a {
    margin: auto;
  }

  .zoox,
  .huawei,
  .qualcomm,
  .christie,
  .synaptive,
  .ppo {
    margin: auto;
    max-height: 90px;
    max-width: 100%;
  }

  .icon-text {
    display: inline-block;
    margin-bottom: 0;
  }

  .badge-pill {
    margin: 10px;
  }

  .indicators {
    transition: color $theme-toggle-time;
    @include themed() {
      color: t("secondary-color");
    }
    text-align: center;
  }

  .indicator-dot {
    margin: 5px;
    opacity: 0.25;
    width: 5px;
    height: 5px;
    transition: opacity 250ms, width 250ms, height 250ms;
  }

  .indicator-active {
    margin: 5px;
    opacity: 1;
    width: 10px;
    height: 10px;
    transition: opacity 250ms, width 250ms, height 250ms;
  }
}

@include media-breakpoint-down(md) {
  .experience-carousel {
    .christie {
      max-width: 50%;
    }

    .icon-text {
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: $max-width-font-reduce) {
  .experience-carousel {
    .zoox,
    .huawei,
    .qualcomm,
    .christie,
    .synaptive,
    .ppo {
      max-height: 50px;
    }

    .badge-pill {
      margin: 3px;
    }

    .experience-bullets {
      margin-top: 5px;
    }

    .map-marker {
      margin-left: 5%;
    }

    .calendar,
    .map-marker {
      margin-right: 5px;
    }
  }
}
