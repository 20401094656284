// Inspired by:
// https://medium.com/@katiemctigue/how-to-create-a-dark-mode-in-sass-609f131a3995
// https://medium.com/@dmitriy.borodiy/easy-color-theming-with-scss-bc38fd5734d1

$themes: (
  light: (
    background-image: url("../../img/cubes.png"),
    background-color: white,
    text-color: black,
    secondary-color: #0e49b5,
    footer-color: #f8f9fa,
    footer-text: rgba(0, 0, 0, 0.5),
  ),
  dark: (
    background-image: url("../../img/cubes2.png"),
    background-color: #1e2124,
    text-color: white,
    secondary-color: #217bcf,
    footer-color: #343a40,
    footer-text: rgba(255,255,255,.5),
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
