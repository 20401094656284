@import "bootstrap";
@import "themes";
@import "global";

#projects {
  margin-top: $spacing-between-sections;

  h1 {
    margin-left: 5%;

    hr {
      margin-top: 3%;
    }
  }

  h2 {
    font-size: 1.5rem;
    display: inline-block;
  }

  .project-row {
    margin-left: 5%;
    margin-right: 5%;
  }

  .link-icon {
    transition: color $theme-toggle-time;

    @include themed() {
      color: t("secondary-color");
    }

    margin-left: 10px;
    margin-top: -10px;
    height: 20px;
    width: 20px;
  }

  .link-icon:hover {
    opacity: 0.5;
  }

  .badge-pill {
    font-size: 0.9rem;
    margin: 3px;
  }

  .card {
    height: 100%;
  }

  .card-body {
    padding-bottom: 0px;
  }

  ul {
    text-align: left;
    margin-top: 10px;
  }

  .card-col {
    margin-top: 30px;
  }

  .project-row {
    margin-top: -30px;
  }
}

@media screen and (max-width: $max-width-font-reduce) {
  #projects {
    .badge-pill {
      font-size: 0.7rem;
    }

    h2 {
      font-size: 1rem;
    }

    .card-body {
      padding-top: 5px;
    }

    .project-row {
      margin-top: -10px;
    }

    .card-col {
      margin-top: 10px;
    }
  }
}