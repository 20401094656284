@import "bootstrap";
@import "themes";
@import "global";

#experience {
  margin-top: $spacing-between-sections;

  h1 {
    margin-left: 5%;
  
    hr {
      margin-top: 3%;
    }
  }
}